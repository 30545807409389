export const API = {
  root: `${process.env.REACT_APP_API_ROOT}`,
};

export const keys = {
  stripe: `${process.env.REACT_APP_STRIPE_KEY_PUBLIC}`,
};

export const ENV_NAME = `${process.env.REACT_APP_ENVIRONMENT}`;

export const cookies = {
  admin: `thehost_${ENV_NAME}_auth_admin`,
  owner: `thehost_${ENV_NAME}_auth_owner`,
  guest: `thehost_${ENV_NAME}_auth_guest`,
  ownerrez: `thehost_${ENV_NAME}_auth_ownerrez`,
};

export const MEDIA_QUERIES = {
  sm: '(min-width: 480px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1024px)',
  xxl: '(min-width: 1440px)',
};

export const SOCIAL_PROVIDERS = {
  'google-oauth2': 'Google',
  facebook: 'Facebook',
};

export const SOCIAL_ERROR_CODES = {
  alreadyAssociated: 'AuthAlreadyAssociated',
};

export const IS_STAGING = process.env.REACT_APP_STAGING === 'true';

export const LEADDYNO_RELEASE_DATE = new Date('2024-07-22');
export const LEADDYNO_PUBLIC_KEY = process.env.REACT_APP_LEADDYNO_PUBLIC_API_KEY;

export const YOU_DO_NOT_HAVE_PERMISSION_TO_THIS_ACTION_ERROR =
  'You do not have permission to perform this action.';
