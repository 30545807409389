import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Tooltip, Typography } from 'ui-kit';
import { ERefundType } from './contants';
import { IOrder, IOrderProduct, refundOrder, refundOrderProduct } from 'api/order_sdk';
import _ from 'lodash';
import { formatCurrency } from 'utils';
import LoadingModal from 'components/LoadingModal';
import { message } from 'antd';
import { usePollingRefund } from './utils';

export interface IRefundButton {
  title: string;
  disabled: boolean;
  refundType: ERefundType;
  order: IOrder;
  onRefundCompleted: () => void;
  product?: IOrderProduct;
  nonRefundableReason?: string;
}

const RefundButton = ({
  title,
  disabled,
  refundType,
  order,
  onRefundCompleted,
  product,
  nonRefundableReason,
}: IRefundButton) => {
  const [isRefunding, setIsRefunding] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [itemIdForRefund, setItemIdForRefund] = useState<number | null>(null);

  const refundAmount = useMemo(
    () =>
      refundType === ERefundType.ORDER_PRODUCT && !_.isUndefined(product)
        ? parseFloat(product.amount_to_refund)
        : order.grand_total,
    [order, product],
  );

  const handleRefund = useCallback(() => {
    setIsRefunding(true);

    if (refundType === ERefundType.ORDER) {
      refundOrder({ orderId: order.id })
        .then(() => {
          setIsOpenConfirmModal(false);
          setItemIdForRefund(order.id);
        })
        .catch((error) => message.error(error.message));
    } else if (refundType === ERefundType.ORDER_PRODUCT && !_.isUndefined(product)) {
      refundOrderProduct({ orderProductId: product.id })
        .then(() => {
          setIsOpenConfirmModal(false);
          setItemIdForRefund(product.id);
        })
        .catch((error) => message.error(error.message));
    }
  }, [refundType, order, product]);

  const { isTaskReady } = usePollingRefund(refundType, itemIdForRefund);

  useEffect(() => {
    if (isTaskReady) {
      onRefundCompleted();
      setIsRefunding(false);
      message.info('Your refund has been processed successfully.');
    }
  }, [isTaskReady, onRefundCompleted]);

  if (isRefunding) {
    return (
      <LoadingModal
        title="Refunding"
        topCopy="Your refund is being processed."
        bottomCopy="Please don’t leave or refresh this page until the process is done."
        isVisible={isRefunding}
      />
    );
  }

  return (
    <>
      <Tooltip title={nonRefundableReason}>
        <span>
          {/*  The button should be wrap with span tag to show the tooltip when the button is disabled */}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setIsOpenConfirmModal(true)}
            disabled={disabled}
          >
            {title}
          </Button>
        </span>
      </Tooltip>
      <Modal
        visible={isOpenConfirmModal}
        okText={title}
        title="Confirm refund"
        onOk={handleRefund}
        onCancel={() => setIsOpenConfirmModal(false)}
      >
        {refundType === ERefundType.ORDER_PRODUCT && !_.isUndefined(product) ? (
          <div>
            <Typography variant="body2" style={{ marginBottom: 8 }}>
              Are you sure you want to refund <strong>{order.guest_name}</strong> who purchased{' '}
              <strong>{product.product_name}</strong> for{' '}
              <strong>{formatCurrency(refundAmount, product.currency)}</strong>?
            </Typography>
            <Typography variant="body2">This cannot be reversed.</Typography>
          </div>
        ) : (
          <div>
            <Typography variant="body2" style={{ marginBottom: 8 }}>
              Are you sure you want to refund <strong>{order.guest_name}</strong> for{' '}
              <strong>{order.products.map((p) => p.product_name).join(', ')}</strong> with amount{' '}
              <strong>{formatCurrency(refundAmount, order.currency)}</strong>?
            </Typography>
            <Typography variant="body2">This cannot be reversed.</Typography>
          </div>
        )}
      </Modal>
    </>
  );
};

export default RefundButton;
